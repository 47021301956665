import { useState, useEffect } from "react";
import _ from "lodash";

export interface ViewportSize {
  width: number;
  height: number;
}

/**
 * Return object with current screen width and height. Rerenders on Resize.
 */

export default function useViewPortSize(): ViewportSize {
  const [viewPortSize, setViewPortSize] = useState<ViewportSize>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setViewPortSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    }, 100);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return viewPortSize;
}
