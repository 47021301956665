export default function mapRange(
    n: number,
    start1: number,
    stop1: number,
    start2: number,
    stop2: number
): number | false {
    // Check that n is within range of start1 and stop1
    if (n < start1 || n > stop1) return false;

    return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
}
