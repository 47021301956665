import styles from "./../header/header.module.scss";

export default function DownloadButton() {
  return (
    <button className={styles.DownloadButton}>
      <a href="/fonts/TrilleGX.ttf" target="_blank" rel="noreferrer">
        Download TrilleGX.ttf
      </a>
    </button>
  );
}
