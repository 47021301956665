import { useRef } from "react";
import { SwipeState } from "../trille";

interface SwipeOutput {
    onTouchStart: React.TouchEventHandler;
    onTouchMove: React.TouchEventHandler;
    onTouchEnd: () => void;
}

export default function useTouch(
    setSwipe: React.Dispatch<SwipeState>
): SwipeOutput {
    const lastX = useRef<number>();

    const minDist = 2;

    const onTouchStart = (event: React.TouchEvent) => {
        lastX.current = event.targetTouches[0].clientX;
    };

    const onTouchMove = (event: React.TouchEvent) => {
        if (!lastX.current) return;

        const currentX = event.targetTouches[0].clientX;
        const dist = currentX - lastX.current;

        // Swipe state is reset if finger has moved less than the minimum distance
        if (Math.abs(dist) < minDist) return setSwipe(null);

        // Positive X distance (Swipe right)
        if (dist > minDist) {
            setSwipe("right");
        }

        // Negative x distance (Swipe left)
        if (dist < -minDist) {
            setSwipe("left");
        }
    };

    const onTouchEnd = () => {
        // Reset
        lastX.current = undefined;
        setSwipe(null);
    };

    return { onTouchStart, onTouchMove, onTouchEnd };
}
