import React from "react";
import styles from "./layout.module.scss";

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Layout(props: Props) {
    const { children } = props || null;
    const { className = "" } = props || "";
    return (
        <section className={`${className} ${styles.Wrapper}`}>
            {children}
        </section>
    );
}
