import Trille from "../trille";
import Layout from "../layout";
import Header from "../header";
import { useEffect, useState } from "react";
import TouchInputSelecter from "../touchInput";
import isTouchDevice from "../utils/isTouchDevice";
import {
  TouchInput,
  TouchPermission,
  isTiltSupported,
  getTouchPermission,
} from "../utils/tilt";

const label = getLabel(50, 10);

export default function App() {
  const [touchPermission, setTouchPermission] = useState<TouchPermission>();
  const [touchInput, setTouchInput] = useState<TouchInput>();
  const tiltSupported = isTiltSupported();
  const isTouch = isTouchDevice();

  // Check if touch permission is needed (iOS13+)
  useEffect(() => {
    if (touchInput === "TOUCH") return;
    if (touchPermission !== undefined) return;

    // Use Touch if tilt is not supported
    if (!tiltSupported) {
      alert("Tilt is not supported for this device");
      return setTouchInput("TOUCH");
    }

    // iOS 13+ device that needs permission
    if (typeof (DeviceMotionEvent as any).requestPermission === "function") {
      getTouchPermission(setTouchPermission);
    }
  }, [touchInput, touchPermission, tiltSupported]);

  return (
    <>
      {/* Get input type if touch device, tilt is supported method is undefined */}
      {isTouch && tiltSupported && touchInput === undefined && (
        <TouchInputSelecter setTouchInput={setTouchInput} />
      )}

      <Layout>
        <Header />
        <Trille
          label={label}
          touchInput={touchInput}
          touchPermission={touchPermission}
        />
      </Layout>
    </>
  );
}

function getLabel(lines: number, repeats: number): string {
  let label = "";
  const template = `TRILLE ((VAR)) ABC \\\\\\\\123//// ,.XYZ:;`;
  const parts = template.split(" ");

  for (let i = 0; i < lines; i++) {
    const start = parts.slice(i % parts.length, parts.length);
    const end = parts.slice(0, i % parts.length);
    const rearranged = start.join(" ") + end.join(" ");

    label += `${Array.from({ length: repeats })
      .map(() => rearranged + " ")
      .join(" ")}\n`;
  }

  return label;
}
