import { useEffect, useMemo, useState } from "react";
import { WDTH_INITIAL, WDTH_MAX, WDTH_MIN } from "../trille";
import { TouchInput, TouchPermission } from "../utils/tilt";
import useTilt from "./useTilt";
import calculateIncrement from "../utils/calculateIncrement";

const TILT_THRESHOLD: number = 10;

export default function useTiltMovement(
    touchInput: TouchInput,
    touchPermission: TouchPermission
): number {
    const [wdth, setWdth] = useState<number>(WDTH_INITIAL);
    const tilt = useTilt(touchPermission);

    const wdth_increment: number = useMemo(
        () => calculateIncrement(WDTH_MIN, WDTH_MAX),
        []
    );

    useEffect(() => {
        if (!tilt) return;

        if (touchInput === "TOUCH") return;

        const calculateWdth = (): number => {
            // Positive
            if (tilt.gamma > TILT_THRESHOLD) {
                return wdth + wdth_increment > WDTH_MAX
                    ? WDTH_MAX
                    : wdth + wdth_increment;
            }

            // Negative
            if (tilt.gamma < TILT_THRESHOLD) {
                return wdth - wdth_increment < WDTH_MIN
                    ? WDTH_MIN
                    : wdth - wdth_increment;
            }

            return 35; // Fallback
        };

        const wdthValue = calculateWdth();

        if (!wdthValue) return;

        setWdth(wdthValue);
    }, [tilt, wdth, touchInput, wdth_increment]);

    return wdth;
}
