import { TouchInput } from "../utils/tilt";
import styles from "./touchInput.module.scss";

interface Props {
    setTouchInput: (touchInput: TouchInput) => void;
}

export default function TouchInputSelecter({ setTouchInput }: Props) {
    return (
        <div className={styles.Wrapper}>
            <button
                className={styles.TouchInput}
                onClick={() => setTouchInput("MOTION")}
            >
                Device Tilt
            </button>
            <button
                className={styles.TouchInput}
                onClick={() => setTouchInput("TOUCH")}
            >
                Touch
            </button>
        </div>
    );
}
