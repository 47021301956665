import { useState, useEffect } from "react";

export type MousePosition = {
    x: number;
    y: number;
};

/**
 * Returns object with current mouse position {x: ..., y: ...}. Rerenders on mouseMove
 */
export default function useMousePosition(): MousePosition | undefined {
    const [mousePosition, setMousePosition] = useState<MousePosition>();

    // Set up eventlistener on mount
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            const xPosition = event.clientX; // pageX vs clientX?
            const yPosition = event.clientY;

            setMousePosition({
                x: xPosition,
                y: yPosition,
            });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => window.removeEventListener("mousemove", handleMouseMove);
    }, []);

    return mousePosition;
}
