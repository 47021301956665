import {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    ReactNode,
} from "react";

/*
 * Custom Context provider
 * Provides Getter and Setter used to center Trille-text depending on Header height.
 */

// Holds height value
const TrilleContext = createContext(0);

// Holds setHeight method
const TrilleUpdateContext = createContext<Dispatch<SetStateAction<number>> | null>(null); // prettier-ignore

interface Props {
    children: ReactNode;
}

export default function TrilleProvider({ children }: Props) {
    const [height, setHeight] = useState<number>(0);

    return (
        <TrilleContext.Provider value={height}>
            <TrilleUpdateContext.Provider value={setHeight}>
                {children}
            </TrilleUpdateContext.Provider>
        </TrilleContext.Provider>
    );
}

// Custom hook: Read context value
export function useTrilleContext() {
    return useContext(TrilleContext);
}

// Custom hook: Get update function
export function useTrilleUpdateContext() {
    return useContext(TrilleUpdateContext);
}
