import { useEffect, useState } from "react";
import { TouchPermission } from "../utils/tilt";

export type Tilt = { beta: number; gamma: number } | undefined | null;

export default function useTilt(permission: TouchPermission): Tilt {
    const [tilt, setTilt] = useState<Tilt | undefined>();

    useEffect(() => {
        // granted: permission is granted by request (iOS13+)
        // undefined: permission is not needed
        if (permission === "granted" || undefined) {
            window.addEventListener("deviceorientation", handleTilt, true);

            return () => {
                window.removeEventListener(
                    "deviceorientation",
                    handleTilt,
                    true
                );
            };
        } else {
            setTilt(null);
        }
    }, [permission]);

    const handleTilt = (event: DeviceOrientationEvent) => {
        const beta: number | null = event.beta; // Axis: Top to bottom: Ranging from -180 (inclusive) to 180 (exclusive)
        const gamma = event.gamma; // Axis: Left to right: Ranging from -90 (inclusive) to 90 (exclusive)

        if (beta && gamma) {
            console.log(`beta: ${beta}, gamma: ${gamma}`);

            setTilt({ beta: beta, gamma: gamma });
        }
    };

    return tilt;
}
