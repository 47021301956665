import { useLayoutEffect, useRef, useState } from "react";
import DownloadButton from "../downloadButton";
import styles from "./header.module.scss";
import { useTrilleUpdateContext } from "../trilleContext";
import useViewPortSize from "../hooks/useViewportSize";

export default function Header() {
    const ref = useRef<HTMLElement | null>(null);
    const setHeight = useTrilleUpdateContext();
    const viewportSize = useViewPortSize();

    const [isOpen, setIsOpen] = useState(false);

    const toggleHeader = () => setIsOpen(() => !isOpen);

    useLayoutEffect(() => {
        if (!ref.current) return;
        if (!setHeight) return;

        const height = ref.current.offsetHeight;
        setHeight(height);
    }, [setHeight, viewportSize]);

    return (
        <header
            ref={ref}
            className={styles.Header}
            data-state={isOpen ? "open" : "closed"}
        >
            <button className={styles.HeaderButton} onClick={toggleHeader}>
                {isOpen ? "Close" : "About Trille"}
            </button>

            <div className={styles.ContentWrapper}>
                <p className={styles.Description}>
                    Trille is a free variable font originally developed in ’21
                    for a book project. Its modular construction and open
                    contours allow for extreme width contrast. Legibility is not
                    guaranteed. Trille is inspired by a poster sketch from the
                    book "Christiana Plakater 1971-1978”. Website by{" "}
                    <a
                        href="https://www.instagram.com/likedittwice/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Rasmus Svane
                    </a>
                    . Type by{" "}
                    <a
                        href="http://pawpoulsen.dk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Paw Poulsen
                    </a>
                    .
                </p>

                <DownloadButton />
            </div>
        </header>
    );
}
