import { useEffect, useMemo, useState } from "react";
import { SwipeState, WDTH_INITIAL, WDTH_MAX, WDTH_MIN } from "../trille";
import { TouchInput } from "../utils/tilt";
import useResolution from "./useResolution";
import calculateIncrement from "../utils/calculateIncrement";

export default function useTouchMovement(
    touchInput: TouchInput,
    swipe: SwipeState
): number {
    const [wdth, setWdth] = useState<number>(WDTH_INITIAL);
    const resolution = useResolution();

    const wdth_increment: number = useMemo(
        () => calculateIncrement(WDTH_MIN, WDTH_MAX),
        []
    );

    useEffect(() => {
        if (!swipe) return;
        if (!resolution) return;
        if (touchInput === "MOTION") return;

        // Positive distance (Swipe right)
        if (swipe === "right") {
            setWdth((wdth) =>
                wdth + wdth_increment > WDTH_MAX
                    ? WDTH_MAX
                    : wdth + wdth_increment
            );
        }

        // Negative distance (Swipe left)
        if (swipe === "left") {
            setWdth((wdth) =>
                wdth - wdth_increment < WDTH_MIN
                    ? WDTH_MIN
                    : wdth - wdth_increment
            );
        }
    }, [swipe, wdth, resolution, touchInput, wdth_increment]);

    return wdth;
}
