import { Fragment, ReactNode } from "react";

export default function addLineBreak(str: string): ReactNode[] {
    return str.split("\n").map((subStr, index) => {
        return (
            <Fragment key={index}>
                {subStr}
                <br />
            </Fragment>
        );
    });
}
