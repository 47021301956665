import { useEffect, useState } from "react";
import useMousePosition from "./useMousePosition";
import useViewPortSize from "./useViewportSize";
import isTouchDevice from "../utils/isTouchDevice";
import mapRange from "../utils/mapRange";
import {
    FONTSIZE_INITIAL,
    FONTSIZE_MAX,
    FONTSIZE_MIN,
    Values,
    WDTH_INITIAL,
    WDTH_MAX,
    WDTH_MIN,
} from "../trille";

export default function useMouseMovement(): Values {
    const [values, setValues] = useState<Values>([
        WDTH_INITIAL,
        FONTSIZE_INITIAL,
    ]);

    // Mouse movement
    const mousePosition = useMousePosition();
    const viewportSize = useViewPortSize();

    useEffect(() => {
        if (isTouchDevice()) return;
        if (!mousePosition) return;

        // Remap values from mouseposition to wdth and fontsize range.

        const wdth = mapRange(
            mousePosition.x,
            0,
            viewportSize.width,
            WDTH_MIN,
            WDTH_MAX
        );

        const fontSize = mapRange(
            mousePosition.y,
            0,
            viewportSize.height,
            FONTSIZE_MIN,
            FONTSIZE_MAX
        );

        if (!wdth) return;
        if (!fontSize) return;

        setValues([wdth, fontSize]);
    }, [mousePosition, viewportSize]);

    return values;
}
