import { useEffect, useRef, useState } from "react";
import styles from "./trille.module.scss";
import useTouch from "../hooks/useTouch";
import { TouchInput, TouchPermission } from "../utils/tilt";
import useMouseMovement from "../hooks/useMouseMovement";
import useTouchMovement from "../hooks/useTouchMovement";
import useTiltMovement from "../hooks/useTiltMovement";
import addLineBreak from "../utils/addLineBreaks";

interface Props {
  label: string;
  touchInput: TouchInput;
  touchPermission: TouchPermission;
}

export type Values = [number, number];
export type SwipeState = "left" | "right" | null | undefined;

export const WDTH_INITIAL: number = 35;
export const WDTH_MIN: number = 1;
export const WDTH_MAX: number = 71;

export const FONTSIZE_INITIAL: number = 0.75;
export const FONTSIZE_MIN: number = 0.5;
export const FONTSIZE_MAX: number = 1;

export default function Trille({
  label = "",
  touchInput,
  touchPermission,
}: Props) {
  const containerRef = useRef(null);

  const [swipe, setSwipe] = useState<SwipeState>();
  const touchHandlers = useTouch(setSwipe);

  // Key states
  const [wdth, setWdth] = useState(WDTH_INITIAL);
  const [fontSize, setFontSize] = useState(FONTSIZE_INITIAL);

  // Inputs
  const mouseMove = useMouseMovement();
  const touchMove = useTouchMovement(touchInput, swipe);
  const tiltMove = useTiltMovement(touchInput, touchPermission);

  // Update changes
  useEffect(() => {
    setWdth(mouseMove[0]);
    setFontSize(mouseMove[1]);
  }, [mouseMove]);

  useEffect(() => setWdth(touchMove), [touchMove]);
  useEffect(() => setWdth(tiltMove), [tiltMove]);

  return (
    <div
      ref={containerRef}
      className={`${styles.Trille}`}
      style={{
        fontVariationSettings: `"wdth" ${wdth}`,
        justifyContent: "center",
      }}
    >
      <p
        contentEditable={true}
        suppressContentEditableWarning={true}
        className={styles.Text}
        spellCheck="false"
        onTouchStart={touchHandlers.onTouchStart}
        onTouchMove={touchHandlers.onTouchMove}
        onTouchEnd={touchHandlers.onTouchEnd}
        style={{
          fontSize: `${fontSize}em`,
        }}
      >
        {addLineBreak(label)}
      </p>
    </div>
  );
}
