export type TouchInput = "TOUCH" | "MOTION" | undefined;
export type TouchPermission = "granted" | "denied" | undefined;

// Get permission to read sensor on iOS 13+
export function getTouchPermission(
    setTouchPermission: (touchPermission: TouchPermission) => void
) {
    (window.DeviceMotionEvent as any)
        .requestPermission()
        .then((state: PermissionState) => {
            if (state === "granted") {
                setTouchPermission(state);
            } else {
                setTouchPermission("denied");
                console.error("Request to access the orientation was rejected");
            }
        })
        .catch(console.error);
}

export function isTiltSupported(): boolean {
    return window.DeviceOrientationEvent ? true : false;
}
