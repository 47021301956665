import { useEffect, useRef } from "react";

export default function useResolution() {
    const resolution = useRef<number>();

    useEffect(() => {
        if (resolution.current) return;
        resolution.current = window.devicePixelRatio;
    }, []);

    return resolution.current;
}
